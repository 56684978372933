import { createRouter, createWebHistory } from "vue-router";
import Login from "@/components/Login.vue";
import MainLayout from "@/components/MainLayout.vue"; // Ensure the correct path

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/main",
    component: MainLayout,
    children: [
      {
        path: "map",
        component: () => import("@/views/Map.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: "report",
        component: () => import("@/views/Report.vue"),
      },
      {
        path: "reportMammal",
        component: () => import("@/views/ReportMammal.vue"),
      },
      {
        path: "reportContainer",
        component: () => import("@/views/ReportContainer.vue"),
      },
      {
        path: "settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "viewData",
        component: () => import("@/views/ViewData.vue"),
      },
      {
        path: "viewDataLocal",
        component: () => import("@/views/ViewDataLocal.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/",
});

export default router;
