<template>
  <div class="loginView">
    <div class="login-form mt-1">
      <div class="section">
        <img
          src="img/logo.png"
          alt="icon"
          class="form-image"
          style="margin-top: 50px"
        />
      </div>
      <div class="section mt-1">
        <h1>Log In</h1>
        <h4>Registration available at https://ocean</h4>
      </div>
      <div class="section mt-1 mb-5">
        <form action="app-pages.html">
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="email"
                class="form-control"
                id="email1"
                placeholder="Email address"
                v-model="email"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
              <p v-if="emailError" id="emailMessage" class="text-danger">
                {{ emailError }}
              </p>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                id="password1"
                placeholder="Password"
                autocomplete="off"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-links mt-2">
            <div hidden>
              <a href="#">Register Now</a>
            </div>
            <div>
              <a href="page-forgot-password.html" class="text-muted"
                >Forgot Password?</a
              >
            </div>
          </div>

          <!--Settings-->
          <div class="form-group boxed" style="margin-top: 30px">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg btn-ocean-action"
              @click="login"
            >
              Log in
            </button>
          </div>
        </form>
      </div>

      <div class="section mt-1">
        <h1>Disclaimer</h1>
        <h4>
          This is NOT a navigation app and is for informational purposes only
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/db";
export default {
  data() {
    return {
      email: null,
      password: null, // Added to manage the password input similarly, if needed
      emailError: "",
    };
  },
  methods: {
    validateEmail() {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = "Email address is required";
        return false;
      } else if (!re.test(this.email)) {
        this.emailError = "Please enter a valid email address";
        return false;
      }
      this.emailError = "";
      return true;
    },
    async login() {
      if (!this.validateEmail()) {
        return; // Stop the login process if validation fails
      }
      try {
        // Perform login validation here
        // Placeholder: Assume validation is successful

        // Check if the settings already exist
        const id = 1; // Fixed ID for the settings entry
        let settings = await db.Settings.get(id);
        if (settings) {
          // Update existing settings
          await db.Settings.update(id, { Email: this.email });
        } else {
          // Create new settings if not exist
          await db.Settings.add({
            SettingId: id,
            Email: this.email,
            // Include other default settings values if necessary
          });
        }

        // Route to the next page upon successful login and DB operation
        this.$router.push("/main/map");
      } catch (error) {
        console.error("Failed to log in or save settings:", error);
        // Handle errors, maybe show an error message to the user
      }
    },
  },
};
</script>

<style scoped>
html {
  font-size: 20px !important;
}
.form-control {
  font-size: 16px;
}
.btn {
  border: 1px solid rgb(214, 214, 214);
}
.btn-primary {
}

#emailMessage {
  padding-top: 10px;
}
</style>
