<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useGeolocation } from "@/geolocation";

export default {
  name: "Ocean",
  async created() {
    await this.$store.dispatch("fetchSettings");
  },
  computed: {
    ...mapState({
      latitude: state => state.geoLoc.lat,
      longitude: state => state.geoLoc.lng,
      speed: state => state.geoLoc.speed,
      heading: state => state.geoLoc.heading,
    }),
  },
  mounted() {
    const { checkPermissionAndTrack } = useGeolocation();
    checkPermissionAndTrack();
    // /startTracking();
  },
  beforeUnmount() {
    const { stopTracking } = useGeolocation();
    stopTracking();
  },
};
</script>
