import Dexie from "dexie";

const db = new Dexie("Ocean_DB");

//DELETE DATABASE
//const dbName = 'Ocean_DB'
//const request = indexedDB.deleteDatabase(dbName)

db.version(1).stores({
  Sighting:
    "reportID, deviceId, firstName, lastName, emailAddress, vesselName, vesselIMO, vesselLat, vesselLong, objectLat, objectLong, timeOfObservation, vesselSpeed, vesselHeading, speedOfObject, objectType, deadOrAlive, distanceToObject, numberOfAnimals, lengthOfLargestAnimal, blowsVisible, behaviour, species, containerSize, numberOfContainers, containerType, containerIDs, containerColour, containerStatus, numberObjects, lengthOfLargestObject, colourObject, descriptionObjects , synced",
  Warnings:
    "ID,validFromTS,expiresMins,warningType,description,lat,long,warningLevel",
  ErrorLog:
    "ErrorId, ErrorDescription, FormName, UserId, UserName, CustomerId, TheDateTime",
  Settings:
    "SettingId, Name, Email, Range, Angle, ApiEndpoint, Token, isDarkMode, showUserData",
});

db.open()
  .then(initializeDefaultValues)
  .catch(err => {
    console.error(`Open failed: ${err.stack}`);
  });

async function initializeDefaultValues() {
  // Example for the 'Settings' table
  const hasSettings = await db.Settings.count();
  if (hasSettings === 0) {
    db.Settings.bulkPut([
      {
        SettingId: 1,
        Name: "Default Name",
        Email: "example@example.com",
        Range: 3,
        Angle: 120,
        ApiEndpoint: "https://api.example.com",
        Token: "default-token",
        isDarkMode: false,
        showUserData: true,
      },
      // ... other default settings
    ]);
  }
}

export default db;
