// geolocation/index.js
import { ref } from "vue";
import store from "@/store";

const watchId = ref(null);

export function useGeolocation() {
  const checkPermissionAndTrack = async () => {
    debugger;
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permissionStatus.state === "granted") {
        startTracking();
      } else if (permissionStatus.state === "prompt") {
        startTracking(); // It will prompt the user
      } else {
        startTracking();
        //alert(
        //  "You have denied GeoLocation Permissions. Reporting will not function until enabled."
        //);
        store.commit("setGeolocationError", "Location permission denied");
      }
    } catch (error) {
      reportErrorToServer("Geolocation error", error);
      console.error("Error checking permissions:", error);
      store.commit(
        "setGeolocationError",
        "Failed to check location permission"
      );
    }
  };

  const startTracking = () => {
    console.log("Starting Tracking:");
    const options = {
      maximumAge: 0,
      enableHighAccuracy: true,
      timeout: 10000,
    };

    watchId.value = navigator.geolocation.watchPosition(
      position => {
        console.log("New position:", position);
        store.commit("setLocationFound", true);
        store.commit("updateLocation", {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed || 0,
          heading: position.coords.heading || 0,
        });
      },
      error => {
        debugger;
        reportErrorToServer("Geolocation error", error);
        if (error.code === error.PERMISSION_DENIED) {
          store.commit("setGeolocationError", "Location permission denied");
        }
      }
    );
  };

  const stopTracking = () => {
    if (watchId.value !== null) {
      navigator.geolocation.clearWatch(watchId.value);
      watchId.value = null;
      store.commit("setWatchId", null);
    }
  };

  const reportErrorToServer = (message, error = {}) => {
    // More descriptive error handling
    let detailedError = {
      code: error.code || 0,
      message: error.message || "No error message provided.",
      description: "", // Initialize description
    };

    // Optional: Add more description based on the error code
    switch (error.code) {
      case error.PERMISSION_DENIED:
        detailedError.description = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        detailedError.description = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        detailedError.description =
          "The request to get user location timed out.";
        break;
      default:
        detailedError.description = "An unknown error occurred.";
        break;
    }

    // Construct the API endpoint URL
    const apiUrl = "https://wwsb.evolve-red.com/api/error-log";

    // Prepare the data to be sent to the server
    const data = {
      message: message,
      error: detailedError, // Directly use the object instead of JSON.stringify
      timestamp: new Date().toISOString(), // Record the current time in ISO format
      deviceId: navigator.userAgent, // Include browser and device details
    };

    // Use fetch to send a POST request to the server
    fetch(apiUrl, {
      method: "POST", // Specify the method
      headers: {
        "Content-Type": "application/json", // Specify JSON content type
      },
      body: JSON.stringify(data), // Convert the JavaScript object to a JSON string
    })
      .then(response => {
        if (!response.ok) {
          // If the server response is not OK, throw an error
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse JSON response from the server
      })
      .catch(error => {
        console.error("Error reporting to server:", error); // Log any errors to the console
      });
  };

  return { checkPermissionAndTrack, stopTracking };
}
